import _ from 'lodash'
import CoreApi from '@/api/core'
import RubricModel from '@/model/Rubric.js'
import RubricFilterService from '@/services/rubric/RubricFilterService'
import Config from '@/config'
import RubricFilter from '@/model/RubricFilter'

export const API_NAME = '/rubric'
export const LIMIT = 2000

const state = {
  error: null,
  detail: RubricModel,
  conflictData: null,
  originalSiteId: null,
  list: [],
  all: [],
  totalCount: 0,
  page: 1,
  filter: _.cloneDeep(RubricFilter),
  runningFetches: {}, // site IDs for which a rubric fetch is running
  doneFetches: {} // site IDs for which a rubric fetch has been already done
}

const mutations = {
  setNewRubricsBySite (state, newRubrics) {
    const newRubricsIds = newRubrics.reduce((acc, rubric) => {
      acc[rubric.id] = true
      return acc
    }, {})
    state.all = state.all.filter(rubric => !newRubricsIds[rubric.id]) // removing duplicates
    state.all = state.all.concat(newRubrics)
  },
  setAll (state, data) {
    state.all = data
  },
  addRunningFetch (state, siteId) {
    state.runningFetches[siteId] = true
  },
  removeRunningFetch (state, siteId) {
    delete state.runningFetches[siteId]
  },
  addDoneFetch (state, siteId) {
    state.doneFetches[siteId] = true
  },
  storeList (state, responseData) {
    state.list = responseData.data
    state.totalCount = responseData.totalCount
  },
  storeDetail (state, responseData) {
    state.detail = responseData
  },
  storeConflictData (state, responseData) {
    state.conflictData = responseData
  },
  storeOriginalSiteId (state, responseData) {
    state.originalSiteId = responseData
  },
  setPage (state, page) {
    state.page = page
  },
  setError (state, message) {
    state.error = message
  },
  setFilter (state, filter) {
    state.filter = filter
  }
}

const actions = {
  async fetchRubricsBySiteId ({ state, commit }, siteId) {
    // Custom Sleep Function
    const waitForRunningFetch = (milliseconds) => new Promise((resolve) => setTimeout(resolve, milliseconds))
    const rubricsAreFetching = () => Boolean(state.runningFetches[siteId])
    const rubricsAlreadyFetched = () => Boolean(state.doneFetches[siteId])

    if (siteId) {
      // this prevents from multiple requests running for the same siteId
      while (rubricsAreFetching()) {
        await waitForRunningFetch(500)
        if (rubricsAlreadyFetched()) {
          break
        }
      }

      if (!rubricsAlreadyFetched()) {
        commit('addRunningFetch', siteId)
        await CoreApi().get(
          API_NAME + '' +
          '?limit=' +
          LIMIT +
          '&order[root]=asc&order[lvl]=asc&order[sorting]=desc&filter_in[site]=' +
          siteId
        )
          .then(res => {
            commit('setNewRubricsBySite', res.data.data)
            commit('removeRunningFetch', siteId)
            commit('addDoneFetch', siteId)
          })
          .catch(error => {
            console.error(error)
            commit('removeRunningFetch', siteId)
          })
      }
    }
  },
  /**
   * This is needed for EdonProductListView.vue and EdonProductNewView.vue
   */
  async fetchAll ({ commit }) {
    await CoreApi().get(
      API_NAME + '' +
      '?limit=' +
      LIMIT +
      '&order[root]=asc&order[lvl]=asc&order[sorting]=desc'
    )
      .then(res => {
        commit('setAll', res.data.data)
      })
      .catch(error => {
        console.error(error)
      })
  },
  fetch (store) {
    store.commit('TOGGLE_LOADING', null, { root: true })
    const offset = (store.state.page * Config.defaults.list.limit) - Config.defaults.list.limit
    const url = API_NAME + '?limit=' + Config.defaults.list.limit + '&offset=' + offset + '&order[id]=desc' +
      RubricFilterService.buildFilterQuery(store.state.filter)
    CoreApi().get(url)
      .then(res => {
        store.commit('storeList', res.data)
        store.commit('TOGGLE_LOADING', null, { root: true })
      })
      .catch(error => {
        console.log(error)
        store.commit('TOGGLE_LOADING', null, { root: true })
      })
  },
  async fetchAsync (store) {
    store.commit('TOGGLE_LOADING', null, { root: true })
    const url = API_NAME + '?limit=2000&order[id]=desc' +
      RubricFilterService.buildFilterQuery(store.state.filter)
    return await CoreApi().get(url)
      .then(res => {
        store.commit('storeList', res.data)
        store.commit('TOGGLE_LOADING', null, { root: true })
      })
      .catch(error => {
        console.log(error)
        store.commit('TOGGLE_LOADING', null, { root: true })
        throw error
      })
  },
  async lazyLoadRubricsByIds (store, ids) {
    if (ids?.length > 0) {
      const notLoadedIds = ids.filter(id => !store.state.all.find(rubric => (id === rubric.id)))
      if (notLoadedIds.length > 0) {
        try {
          const response = await CoreApi().get(`${API_NAME}?filter_in[id]=${notLoadedIds}`)
          store.state.all.concat(response.data.data)
          const newAllRubrics = store.state.all.concat(response.data.data)
          store.commit('setAll', newAllRubrics)
        } catch (e) {
          console.error(e)
        }
      }
    }
  },
  async fetchOne (store, id) {
    await CoreApi().get(API_NAME + '/' + id)
      .then(response => {
        store.commit('storeDetail', response.data)
        store.commit('storeOriginalSiteId', response.data.site)
      })
      .catch(error => console.log(error))
  },
  async create (store, record) {
    store.commit('storeConflictData', null)
    return await CoreApi().post(API_NAME, JSON.stringify(record))
      .then(response => {
        if (response.status === 200 && Array.isArray(response.data)) {
          store.commit('storeConflictData', response.data)
          store.commit('setError', null)
        } else if (response.status === 201) {
          store.commit('storeDetail', response.data)
          store.commit('storeOriginalSiteId', response.data.site)
          store.commit('setError', null)
        } else {
          store.commit('setError', 'Error')
        }
      })
      .catch(error => {
        if (error.response.status === 500) {
          store.commit('setError', error.response.status)
        } else if (error.response.status === 400 && error.response.data.routes[0] === 'error_field_not_unique') {
          store.commit('setError', error.response.data.routes[0])
        } else {
          store.commit('setError', error.response.data.error)
        }
      })
  },
  async update (store, record) {
    store.commit('storeConflictData', null)
    return await CoreApi().put(API_NAME + '/' + record.id, JSON.stringify(record))
      .then(response => {
        if (response.status === 200) {
          store.commit('setError', null)
          if (Array.isArray(response.data)) {
            store.commit('storeConflictData', response.data)
          } else if (typeof response.data === 'object') {
            store.commit('storeDetail', response.data)
            store.commit('storeOriginalSiteId', response.data.site)
          } else {
            store.commit('setError', 'unsupported data')
          }
        } else {
          store.commit('setError', 'Error')
        }
      })
      .catch(error => {
        if (error.response.status === 500) {
          store.commit('setError', error.response.status)
        } else {
          store.commit('setError', error.response.data.error)
        }
      })
  },
  async deleteRecord (store, record) {
    return await CoreApi().delete(API_NAME + '/' + record.id)
      .then(response => {
        if (response.status === 204) {
          store.commit('setError', null)
        } else {
          store.commit('setError', 'Error')
        }
      })
      .catch(error => {
        if (error.response.status === 500) {
          store.commit('setError', error.response.status)
        } else {
          store.commit('setError', error.response.data.error)
        }
      })
  },
  async toggleVirtual (store, rubric) {
    store.commit('storeConflictData', null)
    const virtualizeApi = rubric.isVirtualized ? 'unvirtualize' : 'virtualize'
    try {
      const response = await CoreApi().patch(`${API_NAME}/${rubric.id}/${virtualizeApi}`)

      if (response.status === 200 || response.status === 204) {
        store.commit('setError', null)

        if (Array.isArray(response.data)) {
          store.commit('storeConflictData', response.data)
        }
        return response
      } else {
        store.commit('setError', 'Error')
        return Promise.reject(new Error('Error'))
      }
    } catch (error) {
      store.commit('setError', error)
      return Promise.reject(error)
    }
  },
  async inspectPosition (store, record) {
    try {
      return await CoreApi().post(API_NAME + '/inspect-publish-position', record)
    } catch (error) {
      console.error(error)
    }
  }
}

const getters = {
  list (state) {
    return state.list
  },
  all (state) {
    return state.all
  },
  async allRubricsWithSite (state, getters, rootState, rootGetters) {
    const rubrics = state.all
    const preparedRubrics = []
    rubrics.forEach(rubric => {
      const site = rootGetters['site/siteById'](rubric.site)
      const currentUser = rootGetters['user/currentUser']
      let siteTitle = site.title
      if (site.shortTitle !== '') {
        siteTitle = site.shortTitle
      }
      const preparedRubric = {
        id: rubric.id,
        title: siteTitle + ' / ' + rubric.title,
        parent: rubric.parent,
        site: rubric.site
      }
      if (rubric.parent > 0) {
        const parentRubric = getters.rubricById(rubric.parent)
        preparedRubric.title = siteTitle + ' / ' + (parentRubric?.title ?? rubric.parent) + ' - ' + rubric.title
      }
      if (site.enabled && currentUser.sites.includes(site.id)) {
        preparedRubrics.push(preparedRubric)
      }
    })
    return preparedRubrics
  },
  rubricsWithSite (state, getters, rootState, rootGetters) {
    const rubrics = state.all
    const preparedRubrics = []
    rubrics.forEach(rubric => {
      // skip rubric without articles setting enabled
      if (rubric.rubricWithoutArticles === true) {
        return
      }
      const site = rootGetters['site/siteById'](rubric.site)
      const currentUser = rootGetters['user/currentUser']
      let siteTitle = site.title
      if (site.shortTitle !== '') {
        siteTitle = site.shortTitle
      }
      const preparedRubric = {
        id: rubric.id,
        title: siteTitle + ' / ' + rubric.title,
        parent: rubric.parent,
        site: rubric.site
      }
      if (rubric.parent > 0) {
        const parentRubric = getters.rubricById(rubric.parent)
        preparedRubric.title = siteTitle + ' / ' + (parentRubric?.title ?? rubric.parent) + ' - ' + rubric.title
      }
      if (site.enabled && currentUser.sites.includes(site.id)) {
        preparedRubrics.push(preparedRubric)
      }
    })
    return preparedRubrics
  },
  totalCount (state) {
    return state.totalCount
  },
  rubricById (state) {
    return id => state.all.find(rubric => (id === rubric.id))
  },
  originalSiteId (state) {
    return state.originalSiteId
  },
  filter (state) {
    return state.filter
  },
  page (state) {
    return state.page
  },
  error (state) {
    return state.error
  },
  detail (state) {
    return state.detail
  },
  conflictData (state) {
    return state.conflictData
  }
}

export default {
  namespaced: true,
  state,
  mutations,
  actions,
  getters
}
